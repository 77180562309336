import React from 'react';
import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';
import Router from 'next/router';
import Login from '../component/login/login.js';

const { serverRuntimeConfig } = getConfig();
export default class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  static async getInitialProps({ req, res }) {
    if (req && req.headers && req.headers.cookie) {
      const cookies = req.headers.cookie.split(';');
      let apiToken = null;
      cookies.map((cookie) => {
        if (cookie.includes('apiToken')) {
          apiToken = cookie.split('=')[1];
        }
      });
      const tokenIsValidRes = await fetch(
        `${serverRuntimeConfig?.API_NOBO_URL}/token/isValid/apiToken`,
        {
          method: 'get',
          headers: {
            'content-type': 'application/json',
            apiToken,
            who: 'manager',
          },
        },
      );
      const tokenIsValid = await tokenIsValidRes.json();
      if (tokenIsValid.status == 200) {
        if (res) {
          res.writeHead(302, {
            Location: '/dashboard',
          });
          res.end();
        } else {
          Router.push('/dashboard');
        }
      }
    }
    return {};
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  render() {
    if (this.state.open)
      return (
        <div>
          <Login />
        </div>
      );
    return <div />;
  }
}
