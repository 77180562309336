import React from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import Link from 'next/link';
import '../../css/app.css';
import getConfig from 'next/config';
import cookies from "js-cookie";

const { publicRuntimeConfig } = getConfig();

async function getLogin(email, password) {
  return fetch(`${publicRuntimeConfig?.API_NOBO_URL}/auth/authenticate`, {
    method: 'post',
    headers: new Headers({
      'content-type': 'application/json',
      who: 'manager',
    }),
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((data) => data.json().then((json) => json));
}

async function checkToken(token) {
  return fetch(`${publicRuntimeConfig?.API_NOBO_URL}/token/isValid/apiToken`, {
    method: 'get',
    headers: {
      'content-type': 'application/json',
      who: 'manager',
      apiToken: token,
    },
  })
    .then((data) => {
      let { status } = data;
      return data.json().then((json) => json);
    })
    .catch((err) => {
      console.log(err);
    });
}
class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
      loading: Boolean,
    };
  }

  handleInput = ({ target }) => {
    this.setState({
      [target.id]: target.value,
    });
  };

  async authenticate() {
    this.setState({ loading: true });
    const login = await getLogin(this.state.email, this.state.password);
    if (login.status === 200) {
      const who = await checkToken(login.apiToken);
      if (who.status === 200) {
        cookies.set('apiToken', login.apiToken);
        const domain = publicRuntimeConfig.SITE_NOBO_URL.replace('https://', '.').replace('/', '');
        cookies.set('x-nobo-api-token', login.apiToken, { domain });
        return this.redirectHome();
      }
    }
    this.setState({
      error: 'Vos identifiants sont invalides',
      loading: false,
    });
  }

  handleKey(event) {
    if (event.key === 'Enter') this.authenticate();
  }

  redirectHome() {
    window.location.href = '/dashboard';
  }

  render() {
    return (
      <div style={{ marginTop: '10%' }}>
        <Grid container justify="center" alignItems="center" direction="row">
          <Paper
            style={{ width: '600px', height: '500px', backgroundColor: '#dfe4ea' }}
            align="center"
          >
            <Grid>
              <Grid item xs={12}>
                <img src="/static/nobo_logo.png" alt="logo" style={{ objectFit: 'contain', width: "250px" }} />
                <div style={{ fontSize: 30 }} align="center">
                  Connexion
                </div>
              </Grid>
              <Grid item style={{ marginBottom: 40, marginTop: 30 }}>
                <TextField
                  id="email"
                  placeholder="Address Mail"
                  onChange={this.handleInput.bind(this)}
                  label="Email"
                />
              </Grid>
              <Grid item style={{ marginBottom: 30, width: '400px' }}>
                <TextField
                  type="password"
                  id="password"
                  placeholder="Mot de passe"
                  onChange={this.handleInput.bind(this)}
                  onKeyPress={this.handleKey.bind(this)}
                  label="Mot de passe"
                // className={classNames(classes.textField, classes.dense)}
                />
              </Grid>
              <br />
              {this.state.error !== '' && <p style={{ color: 'red' }}>{this.state.error}</p>}
              <Button
                color="primary"
                variant="contained"
                disabled={this.state.loading === true}
                onClick={() => {
                  this.authenticate();
                }}
              >
                Se connecter
              </Button>
              <br />
              <br />
              <Link href="/mot-de-passe-oublie">
                <small className="btn-link pointer">Mot de passe oublié</small>
              </Link>
            </Grid>
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default login;
